<template>
  <div class="min-h-screen bg-white p-4">
    <!-- 新增模板选择 -->
    <div class="flex items-center mb-4">
      <span class="w-20 text-gray-700 font-medium">选择模板：</span>
      <select
          v-model="selectedTemplate"
          class="flex-1 border border-gray-300 rounded-md p-2 focus:ring-blue-500 focus:border-blue-500"
      >
        <option value="default">默认模板</option>
        <option value="taikang">泰康模板</option>
      </select>
    </div>

    <!-- 动态字段渲染 -->
    <div class="space-y-4">
      <!-- 公共字段 -->
      <div class="flex flex-col">
        <label class="text-gray-700 font-medium mb-1">{{ getFieldLabel('escort_name') }}</label>
        <input
            v-model="ticket.escort_name"
            class="border border-gray-300 rounded-md p-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="请输入陪诊人员姓名"
        />
      </div>
      <div class="flex flex-col">
        <label class="text-gray-700 font-medium mb-1">{{ getFieldLabel('customer_name') }}</label>
        <input
            v-model="ticket.customer_name"
            class="border border-gray-300 rounded-md p-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="请输入客户姓名"
        />
      </div>
      <div class="flex flex-col">
        <label class="text-gray-700 font-medium mb-1">{{ getFieldLabel('visit_hospital') }}</label>
        <input
            v-model="ticket.visit_hospital"
            class="border border-gray-300 rounded-md p-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="请输入就诊医院"
        />
      </div>
      <div class="flex flex-col">
        <label class="text-gray-700 font-medium mb-1">{{ getFieldLabel('visit_deparment') }}</label>
        <input
            v-model="ticket.visit_deparment"
            class="border border-gray-300 rounded-md p-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="请输入就诊科室"
        />
      </div>
      <div class="flex flex-col">
        <label class="text-gray-700 font-medium mb-1">{{ getFieldLabel('visit_doctor') }}</label>
        <input
            v-model="ticket.visit_doctor"
            class="border border-gray-300 rounded-md p-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="请输入专家名称"
        />
      </div>
      <div v-if="selectedTemplate === 'taikang'" class="flex flex-col">
        <label class="text-gray-700 font-medium mb-1">{{ getFieldLabel('level') }}</label>
        <input
            v-model="ticket.level"
            class="border border-gray-300 rounded-md p-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="请输入级别"
        />
      </div>
      <div class="flex flex-col">
        <label class="text-gray-700 font-medium mb-1">{{ getFieldLabel('register_fee') }}</label>
        <input
            v-model="ticket.register_fee"
            class="border border-gray-300 rounded-md p-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="请输入挂号费"
        />
      </div>
      <div v-if="selectedTemplate === 'default'" class="flex flex-col">
        <label class="text-gray-700 font-medium mb-1">{{ getFieldLabel('patient_disease') }}</label>
        <input
            v-model="ticket.patient_disease"
            class="border border-gray-300 rounded-md p-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="请输入患者基本病情"
        />
      </div>
      <div class="flex flex-col">
        <label class="text-gray-700 font-medium mb-1">{{ getFieldLabel('expert_diagnosis') }}</label>
        <input
            v-model="ticket.expert_diagnosis"
            class="border border-gray-300 rounded-md p-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="请输入专家诊断"
        />
      </div>
      <div v-if="selectedTemplate === 'default'" class="flex flex-col">
        <label class="text-gray-700 font-medium mb-1">{{ getFieldLabel('medical_name') }}</label>
        <input
            v-model="ticket.medical_name"
            class="border border-gray-300 rounded-md p-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="请输入药品名称"
        />
      </div>
      <div v-if="selectedTemplate === 'default'" class="flex flex-col">
        <label class="text-gray-700 font-medium mb-1">{{ getFieldLabel('take_medical_time') }}</label>
        <input
            v-model="ticket.take_medical_time"
            class="border border-gray-300 rounded-md p-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="请输入取药时间段"
        />
      </div>
      <div class="flex flex-col">
        <label class="text-gray-700 font-medium mb-1">{{ getFieldLabel('expert_suggest') }}</label>
        <input
            v-model="ticket.expert_suggest"
            class="border border-gray-300 rounded-md p-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="请输入专家建议"
        />
      </div>
      <div v-if="selectedTemplate === 'taikang'" class="flex flex-col">
        <label class="text-gray-700 font-medium mb-1">{{ getFieldLabel('appointment_time') }}</label>
        <input
            v-model="ticket.appointment_time"
            class="border border-gray-300 rounded-md p-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="请输入约定见面时间"
        />
      </div>
      <div class="flex flex-col">
        <label class="text-gray-700 font-medium mb-1">{{ getFieldLabel('escort_reach_hospital_time') }}</label>
        <input
            v-model="ticket.escort_reach_hospital_time"
            class="border border-gray-300 rounded-md p-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="请输入陪诊到院时间"
        />
      </div>
      <div v-if="selectedTemplate === 'taikang'" class="flex flex-col">
        <label class="text-gray-700 font-medium mb-1">{{ getFieldLabel('cusotomer_reach_hospital_time') }}</label>
        <input
            v-model="ticket.cusotomer_reach_hospital_time"
            class="border border-gray-300 rounded-md p-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="请输入客户到院时间"
        />
      </div>
      <div class="flex flex-col">
        <label class="text-gray-700 font-medium mb-1">{{ getFieldLabel('take_number_time') }}</label>
        <input
            v-model="ticket.take_number_time"
            class="border border-gray-300 rounded-md p-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="请输入取号时间"
        />
      </div>
      <div class="flex flex-col">
        <label class="text-gray-700 font-medium mb-1">{{ getFieldLabel('in_clinic_time') }}</label>
        <input
            v-model="ticket.in_clinic_time"
            class="border border-gray-300 rounded-md p-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="请输入进入诊室时间"
        />
      </div>
      <div class="flex flex-col">
        <label class="text-gray-700 font-medium mb-1">{{ getFieldLabel('out_clinic_time') }}</label>
        <input
            v-model="ticket.out_clinic_time"
            class="border border-gray-300 rounded-md p-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="请输入出诊室时间"
        />
      </div>
      <div class="flex flex-col">
        <label class="text-gray-700 font-medium mb-1">{{ getFieldLabel('check_project') }}</label>
        <input
            v-model="ticket.check_project"
            class="border border-gray-300 rounded-md p-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="请输入检查项目"
        />
      </div>
      <div v-if="selectedTemplate === 'default'" class="flex flex-col">
        <label class="text-gray-700 font-medium mb-1">{{ getFieldLabel('medical_name') }}</label>
        <input
            v-model="ticket.medical_name"
            class="border border-gray-300 rounded-md p-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="请输入办理检查时间段"
        />
      </div>
      <div v-if="selectedTemplate === 'default'" class="flex flex-col">
        <label class="text-gray-700 font-medium mb-1">{{ getFieldLabel('medical_name') }}</label>
        <input
            v-model="ticket.medical_name"
            class="border border-gray-300 rounded-md p-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="请输入检查预约时间"
        />
      </div>
      <div v-if="selectedTemplate === 'taikang'" class="flex flex-col">
        <label class="text-gray-700 font-medium mb-1">{{ getFieldLabel('is_hospital_cert') }}</label>
        <input
            v-model="ticket.is_hospital_cert"
            class="border border-gray-300 rounded-md p-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="请输入是否开住院证"
        />
      </div>
      <div v-if="selectedTemplate === 'taikang'" class="flex flex-col">
        <label class="text-gray-700 font-medium mb-1">{{ getFieldLabel('is_hospital') }}</label>
        <input
            v-model="ticket.is_hospital"
            class="border border-gray-300 rounded-md p-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="请输入是否需要协调住院"
        />
      </div>
      <div class="flex flex-col">
        <label class="text-gray-700 font-medium mb-1">{{ getFieldLabel('service_end_time') }}</label>
        <input
            v-model="ticket.service_end_time"
            class="border border-gray-300 rounded-md p-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="请输入服务结束时间"
        />
      </div>
      <div class="flex flex-col">
        <label class="text-gray-700 font-medium mb-1">{{ getFieldLabel('remarks') }}</label>
        <textarea
            v-model="ticket.remarks"
            class="border border-gray-300 rounded-md p-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="请输入备注(就诊其他情况)"
        ></textarea>
      </div>
      <!-- 上传区域 -->
      <h3 class="text-lg font-medium text-gray-700 mt-6">上传图片 <span class="text-red-500">* 为必传项</span></h3>
      <div class="upload-section grid grid-cols-1 gap-4 mt-4">
        <div class="upload-item">
          <div class="flex items-center mb-1">
            <span class="text-red-500">*</span>
            <label class="text-gray-700 font-medium ml-1">{{ getFieldLabel('reach_proof_images') }}</label>
          </div>
          <van-uploader
              v-model="reach_proof_images"
              :max-count="1"
              class="border border-gray-300 rounded-md p-2 focus:ring-blue-500 focus:border-blue-500"
              :before-read="uploadBeforeRead"
              :after-read="uploadAfterRead"
          />
        </div>
        <div class="upload-item">
          <div class="flex items-center mb-1">
            <span class="text-red-500">*</span>
            <label class="text-gray-700 font-medium ml-1">{{ getFieldLabel('register_images') }}</label>
          </div>
          <van-uploader
              v-model="register_images"
              :max-count="1"
              class="border border-gray-300 rounded-md p-2 focus:ring-blue-500 focus:border-blue-500"
              :before-read="uploadBeforeRead"
              :after-read="uploadAfterRead"
          />
        </div>
        <div class="upload-item">
          <div class="flex items-center mb-1">
            <span class="text-red-500">*</span>
            <label class="text-gray-700 font-medium ml-1">{{ getFieldLabel('check_images') }}</label>
          </div>
          <van-uploader
              v-model="check_images"
              :max-count="1"
              class="border border-gray-300 rounded-md p-2 focus:ring-blue-500 focus:border-blue-500"
              :before-read="uploadBeforeRead"
              :after-read="uploadAfterRead"
          />
        </div>
        <div class="upload-item">
          <div class="flex items-center mb-1">
            <label class="text-gray-700 font-medium ml-1">{{ getFieldLabel('other_images') }}</label>
          </div>
          <van-uploader
              v-model="other_images"
              :max-count="5"
              multiple
              class="border border-gray-300 rounded-md p-2 focus:ring-blue-500 focus:border-blue-500"
              :before-read="uploadBeforeRead"
              :after-read="uploadAfterRead"
          />
        </div>
      </div>

      <!-- 操作按钮 -->
      <div class="flex justify-between gap-4 mt-6">
        <button
            @click="showRecords('history')"
            class="flex-1 py-2 rounded-md text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50"
        >
          查看记录
        </button>
        <button
            @click="onSave"
            class="flex-1 py-2 rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
        >
          暂存
        </button>
        <button
            @click="onFinish"
            class="flex-1 py-2 rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-50"
        >
          完成服务单
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, watchEffect } from 'vue'
import Compressor from 'compressorjs'
import axios from 'axios'
import { api } from '@/api'
import { useLoadingStore, useToastStore } from '@/stores'
import { $formatDate, $formatErrorMessage, $getCurrentDate } from '@/helpers'
import {getExtension} from "@/utils";

const { $showLoading, $hideLoading } = useLoadingStore()
const { $toast } = useToastStore()

type Tab = 'info' | 'data'
const tab = ref<Tab>('info')

function onChangeTab(value: Tab) {
  tab.value = value
}

// 新增模板类型
type Template = 'default' | 'taikang'
const selectedTemplate = ref<Template>('default')

// 模板字段配置
const templateFields = {
  default: [
    'hospital', 'keshi', 'doctor_name', 'all_registration_fee',
    'disease', 'diagnosis', 'drug_name', 'pickup_time',
    'check_name', 'handle_time', 'appointment_time',
    'treatment_plan', 'arrival_time', 'registration_time',
    'entry_time', 'exit_time', 'end_time', 'remark'
  ],
  taikang: [
    'escort_name', 'customer_name', 'hospital', 'keshi',
    'expert_name', 'all_registration_fee', 'basic_disease',
    'diagnosis', 'drug_name', 'pickup_time', 'check_name',
    'handle_time', 'appointment_time', 'treatment_plan',
    'arrival_time', 'registration_time', 'entry_time',
    'exit_time', 'end_time', 'remark', 'level', 'appointment_time',
    'cusotomer_reach_hospital_time', 'is_hospital_cert', 'is_hospital'
  ]
}

// 定义 ticket 和 reach_proof_images
const ticket = ref({
  escort_name: '',
  customer_name: '',
  visit_hospital: '',
  visit_deparment: '',
  visit_doctor: '',
  level: '',
  register_fee: '',
  patient_disease: '',
  expert_diagnosis: '',
  medical_name: '',
  take_medical_time: '',
  expert_suggest: '',
  appointment_time: '',
  escort_reach_hospital_time: '',
  cusotomer_reach_hospital_time: '',
  take_number_time: '',
  in_clinic_time: '',
  out_clinic_time: '',
  check_project: '',
  service_end_time: '',
  remarks: '',
  is_hospital_cert: '',
  is_hospital: ''
})
const reach_proof_images = ref([])
const register_images = ref([])
const check_images = ref([])
const other_images = ref([])

// 新增查看记录功能
const showRecords = (type: string) => {
  // 这里实现查看记录逻辑，例如打开弹窗显示历史记录
  console.log('查看记录:', type)
}

// 修改保存逻辑添加验证
async function onSave() {
  try {
    // 验证必填字段
    // if (!validateRequiredFields()) {
    //   $toast('请完成所有必填项')
    //   return
    // }

    $showLoading()
    // 暂存数据到本地
    localStorage.setItem('ticketData', JSON.stringify(ticket.value))
    localStorage.setItem('reach_proof_images', JSON.stringify(reach_proof_images.value))
    localStorage.setItem('register_images', JSON.stringify(register_images.value))
    localStorage.setItem('check_images', JSON.stringify(check_images.value))
    localStorage.setItem('other_images', JSON.stringify(other_images.value))
    localStorage.setItem('selectedTemplate', selectedTemplate.value)
    $toast('数据已暂存')
    $hideLoading()
  } catch (error) {
    $toast($formatErrorMessage(error))
    $hideLoading()
  }
}

// 完成服务单逻辑
async function onFinish() {
  try {
    // 验证必填字段
    if (!validateRequiredFields()) {
      $toast('请完成所有必填项')
      return
    }

    $showLoading()
    // 完成服务单逻辑...
    await api.post('https://ipaas.xiaominuo.cn/webhook/addOrder', {
      escort_name: ticket.value.escort_name,
      customer_name: ticket.value.customer_name,
      visit_hospital: ticket.value.visit_hospital,
      visit_deparment: ticket.value.visit_deparment,
      visit_doctor: ticket.value.visit_doctor,
      level: ticket.value.level,
      register_fee: ticket.value.register_fee,
      patient_disease: ticket.value.patient_disease,
      expert_diagnosis: ticket.value.expert_diagnosis,
      medical_name: ticket.value.medical_name,
      take_medical_time: ticket.value.take_medical_time,
      expert_suggest: ticket.value.expert_suggest,
      appointment_time: ticket.value.appointment_time,
      escort_reach_hospital_time: ticket.value.escort_reach_hospital_time,
      cusotomer_reach_hospital_time: ticket.value.cusotomer_reach_hospital_time,
      take_number_time: ticket.value.take_number_time,
      in_clinic_time: ticket.value.in_clinic_time,
      out_clinic_time: ticket.value.out_clinic_time,
      check_project: ticket.value.check_project,
      service_end_time: ticket.value.service_end_time,
      remarks: ticket.value.remarks,
      is_hospital_cert: ticket.value.is_hospital_cert,
      is_hospital: ticket.value.is_hospital,
      selectedTemplate: selectedTemplate.value,
      reach_proof_images: reach_proof_images.value.filter(item => item.status === 'done').map(item => item.url),
      register_images: register_images.value.filter(item => item.status === 'done').map(item => item.url),
      check_images: check_images.value.filter(item => item.status === 'done').map(item => item.url),
      other_images: other_images.value.filter(item => item.status === 'done').map(item => item.url),
    })
    // 清除本地缓存
    localStorage.removeItem('ticketData')
    localStorage.removeItem('reach_proof_images')
    localStorage.removeItem('register_images')
    localStorage.removeItem('check_images')
    localStorage.removeItem('other_images')
    localStorage.removeItem('selectedTemplate')
    $toast('服务单已完成')
    $hideLoading()
  } catch (error) {
    $toast($formatErrorMessage(error))
    $hideLoading()
  }
}

// 页面加载时恢复暂存数据
onMounted(() => {
  const storedTicketData = localStorage.getItem('ticketData')
  if (storedTicketData) {
    ticket.value = JSON.parse(storedTicketData)
  }
  const storedReachProofImages = localStorage.getItem('reach_proof_images')
  if (storedReachProofImages) {
    reach_proof_images.value = JSON.parse(storedReachProofImages)
  }
  const storedRegisterImages = localStorage.getItem('register_images')
  if (storedRegisterImages) {
    register_images.value = JSON.parse(storedRegisterImages)
  }
  const storedCheckImages = localStorage.getItem('check_images')
  if (storedCheckImages) {
    check_images.value = JSON.parse(storedCheckImages)
  }
  const storedOtherImages = localStorage.getItem('other_images')
  if (storedOtherImages) {
    other_images.value = JSON.parse(storedOtherImages)
  }
  const storedSelectedTemplate = localStorage.getItem('selectedTemplate')
  if (storedSelectedTemplate) {
    selectedTemplate.value = storedSelectedTemplate as Template
  }
})

// 必填字段验证
function validateRequiredFields() {
  const requiredFields = [
    'visit_hospital', 'visit_deparment', 'register_fee',
    'escort_reach_hospital_time', 'take_number_time'
  ]
  return requiredFields.every(field =>
      ticket.value[field]?.trim()
  )
}

// 获取字段标签
function getFieldLabel(field: string) {
  switch (field) {
    case 'escort_name':
      return '陪诊人员姓名'
    case 'customer_name':
      return '客户姓名'
    case 'visit_hospital':
      return '就诊医院'
    case 'visit_deparment':
      return '就诊科室'
    case 'visit_doctor':
      return '专家名称'
    case 'level':
      return '级别'
    case 'register_fee':
      return '挂号费'
    case 'patient_disease':
      return '患者基本病情'
    case 'expert_diagnosis':
      return '专家诊断'
    case 'medical_name':
      return '药品名称'
    case 'take_medical_time':
      return '取药时间段'
    case 'expert_suggest':
      return '专家建议'
    case 'appointment_time':
      return '约定见面时间'
    case 'escort_reach_hospital_time':
      return '陪诊到院时间'
    case 'cusotomer_reach_hospital_time':
      return '客户到院时间'
    case 'take_number_time':
      return '取号时间'
    case 'in_clinic_time':
      return '进入诊室时间'
    case 'out_clinic_time':
      return '出诊室时间'
    case 'check_project':
      return '检查项目'
    case 'service_end_time':
      return '服务结束时间'
    case 'remarks':
      return '备注(就诊其他情况)'
    case 'is_hospital_cert':
      return '是否开住院证'
    case 'is_hospital':
      return '是否需要协调住院'
    case 'reach_proof_images':
      return '到医院证明'
    case 'register_images':
      return '挂号条'
    case 'check_images':
      return '调查表'
    case 'other_images':
      return '其他'
    default:
      return field
  }
}

// 判断是否为时间字段
function isTimeField(field: string) {
  return [
    'take_medical_time', 'appointment_time', 'escort_reach_hospital_time',
    'cusotomer_reach_hospital_time', 'take_number_time', 'in_clinic_time',
    'out_clinic_time', 'service_end_time'
  ].includes(field)
}
// 上传前处理
function uploadBeforeRead(file: File) {
  if (Array.isArray(file)) {
    const promises = file.map((item) => {
      return new Promise((resolve) => {
        // compressorjs 默认开启 checkOrientation 选项
        // 会将图片修正为正确方向
        // eslint-disable-next-line no-new
        new Compressor(item, {
          success: resolve,
          error(err: any) {
            console.error(err.message)
          },
        })
      })
    })

    return Promise.all(promises)
  }

  return new Promise((resolve) => {
    // compressorjs 默认开启 checkOrientation 选项
    // 会将图片修正为正确方向
    // eslint-disable-next-line no-new
    new Compressor(file, {
      success: resolve,
      error(err: any) {
        console.error(err.message)
      },
    })
  })
}

// 获取文件扩展名
function getExtension(filename: string) {
  return filename.split('.').pop() || ''
}

// 上传后处理
async function uploadAfterRead(file: { file: File; [key: string]: any }) {
  const files = Array.isArray(file) ? file : [file]
  for (const file of files) {
    // /escort/serv_order/get_upload_params
    file.status = 'uploading'
    file.message = '上传中...'
    // Get Signature from Server
    const { data: signature } = await api.get(`/escort/serv_order/get_upload_params?ext=${getExtension(file.file.name)}`)
    // axios.post()
    try {
      const { status } = await axios.post(signature.host,
          {
            key: signature.key,
            policy: signature.policy,
            OSSAccessKeyId: signature.accessKeyId,
            signature: signature.signature,
            file: file.file,
          },
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
      )

      if (status !== 204) {
        file.status = 'failed'
        file.message = '上传失败'
        return
      }

      file.status = 'done'
      file.url = signature.url
    } catch (error: any) {
      console.error(error)
      file.status = 'failed'
      file.message = '上传失败'
    }
  }
}
// 打开时间选择器
function openTimePicker(field: string) {
  console.log('打开时间选择器:', field)
}
</script>

<style scoped>
/* 可以根据需要添加更多自定义样式 */
</style>
